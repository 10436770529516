import React from "react"
import loadable from '@loadable/component'

import { useBreakpoint } from 'gatsby-plugin-breakpoints';
import SEO from '../components/SEO'

const Logo = loadable(() => import("../components/Logo"));
const Menu = loadable(() => import('../components/Menu'));
const App = loadable(() => import('../components/App'));
const Content = loadable(() => import('../components/Content'));
const Features = loadable(() => import('../components/Features'));
const ImageFeature = loadable(() => import('../components/ImageFeature'));
const Section = loadable(() => import('../components/Section'));
const WhiteInnerSection = loadable(() => import('../components/WhiteInnerSection'));
const InnerSection = loadable(() => import('../components/InnerSection'));
const DogHanging = loadable(() => import('../components/DogHanging'));
const FAQ = loadable(() => import('../components/FAQ'));
const Ads = loadable(() => import('../components/Ads'));
const Video = loadable(() => import('../components/Video'));

export default function Success() {
  const breakpoints = useBreakpoint();

  return (
    <main>
      <SEO/>
      <Ads/>
      <Logo/>
      <Menu home={true}/>
      <Content>
        <Section title='Parabéns pela compra da sua Urdog!' id='congratz'>
          <div style={{height: "100vh"}}>
            <h3>Em breve vamos enviar a sua coleira junto com mais informações por email!</h3>
          </div>
        </Section>
      </Content>

      {/* RD Station */}
      <script type="text/javascript" async src="https://d335luupugsy2.cloudfront.net/js/loader-scripts/e0bc903a-3a06-4880-a685-c4cfb6f1b5d2-loader.js" ></script>
    </main>
  )
}